<script>

import * as _ from 'lodash';
import moment from 'moment';

import BookingProgress from "./new_class_booking/BookingProgress.vue";
import SelectDate from "./new_class_booking/SelectDate.vue";
import SelectLocation from "./new_class_booking/SelectLocation.vue";
import FillDescription from "./new_class_booking/FillDescription.vue";

import FillName from "./new_booking/FillName.vue";
import FillEmail from "./new_booking/FillEmail.vue";
import FillPhone from "./new_booking/FillPhone.vue";
import FillPassword from "./new_class_booking/FillPassword.vue";

export default {
  components: {
    BookingProgress,
    SelectDate,
    SelectLocation,
    FillDescription,
    FillName,
    FillEmail,
    FillPhone,
    FillPassword,
  },
  props: {start_time: String, price: Number, artistSlug: String},
  data() {
    return {
      phoneRegex: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/im,
      emailRegex: /(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/i,
      urlRegex: /((https?:\/\/|www.?)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i,
      moment: moment,
      is_guest: false,
      isShowSelectGenreScreen: true,
      step: localStorage.getItem("booking_step") || "select_artist_type",
      bookingID: null,
      booking: {},
      booking_applications: [],
      isMessageSelected: JSON.parse(localStorage.getItem('isMessageSelected', 'false')),
      guestToken: null,
    };
  },
  mounted() {
    let token = localStorage.getItem("token", "");
    this.guestToken = localStorage.getItem("guest_token", "");
    if(this.guestToken) {
      this.$axios.defaults.headers.common["Authorization"] = token;
      this.getProfile();
      this.getGuestBooking()
    }
    else if (token === null) {
      this.$axios.get("/api_tokens/new.json").then((response) => {
        localStorage.setItem("token", response.data.jwt);
        this.$axios.defaults.headers.common["Authorization"] =
            response.data.jwt;
        this.getProfile();
        this.getBooking();
      });
    } else {
      this.$axios.defaults.headers.common["Authorization"] = token;
      this.getProfile();
      this.getBooking();
    }
  },
  methods: {
    redactedBookingDescription(artTypeDescription) {
      let descriptionContent = artTypeDescription
      let phone = descriptionContent.match(this.phoneRegex)
      if(phone) {
        let descriptionContentChunks = descriptionContent.split(phone[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      let email = descriptionContent.match(this.emailRegex)
      if(email) {
        let descriptionContentChunks = descriptionContent.split(email[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      let url = descriptionContent.match(this.urlRegex)
      if(url) {
        let descriptionContentChunks = descriptionContent.split(url[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      return descriptionContent
    },
    getGuestBooking() {
      this.$axios.get("/v3/class_bookings/new", { headers: {
          Authorization: localStorage.getItem('guest_token', ''),
        }
      }).then((response)=> {
        this.booking = response.data;
        this.$axios.put(`/v3/class_bookings/${response.data.id}/transfer_booking`)
        .then(response => {
          this.getBooking()
          localStorage.removeItem("class_booking");
          localStorage.removeItem("guest_token");
        })
      })
    },
    getProfile() {
      this.$axios.get('/v3/users/profile')
          .then(response => {
            this.is_guest = response.data.role === 'guest'
          })
    },
    getBooking() {
      this.$axios.get("/v3/class_bookings/new").then((response) => {
        const url = new URL(window.location.href);
        if (this.guestToken) {
          this.bookingID = this.booking.id;
          this.booking_applications = this.booking.class_booking_applications;
        } else {
          this.booking = response.data;
          this.bookingID = this.booking.id;
          this.booking_applications = response.data.class_booking_applications;
        }
        if (this.guestToken) {
          this.updateStep({
            next_step: "booking_complete"
          })
        } else {
          this.updateStep({ next_step: "select_date"});
        }
      });
    },
    updateStep(params) {
      this.isLoading = true
      if (params.booking_params) {
        this.$axios.put(`/v3/class_bookings/${this.bookingID}`, params.booking_params)
            .then(response => {
              this.booking = response.data
              this.booking_applications = this.booking.booking_applications;
              this.saveCurrentStep(params.next_step)
              if (params.next_step === 'booking_complete') this.completeBooking()
              window.initMap()
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
            })
      } else {
        if (params.next_step === 'booking_complete') { 
          window.initMap()
          this.completeBooking()
        }
        this.saveCurrentStep(params.next_step)
      }
    },
    completeBooking() {
      this.$axios.put(`/v3/class_bookings/${this.bookingID}/complete`)
      .then(response => {
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    saveCurrentStep(current_step) {
      if (current_step === "done" || current_step === "booking_complete") {
        localStorage.removeItem("booking_step");
      } else {
        localStorage.setItem("booking_step", current_step);
        let artistInUrl = window.location.href.includes("artist")
        if (!artistInUrl){
          const bookingUrl = `${window.location.origin}/instant_quote`
          window.history.pushState({ path: bookingUrl }, '', bookingUrl)
        }
      }
      this.step = current_step;
      window.scroll({ top: 0, left: 0 });
    },
    complete() {
      this.updateStep({ next_step: "done"});
      window.location.href = `/event_bookings/${this.bookingID}?type=class_booking`;
    },
    goToMessenger() {
      this.updateStep({ next_step: "done"});
      window.location.href = '/messenger';
    }
  },
  computed: {
    activeStepNum() {
      let stepNumbers = {
        select_date: 1,
        select_location: 2,
        fill_description: 3
      }

      const guestSteps = {
        fill_name: 4,
        fill_phone: 5,
        fill_email: 6,
        fill_password: 7,
        booking_complete: 8
      }

      const regularUserSteps = {
        booking_complete: 4
      }

      if (this.is_guest) {
        stepNumbers = { ...stepNumbers, ...guestSteps }
      } else {
        stepNumbers = { ...stepNumbers, ...regularUserSteps }
      }

      let activeStep = stepNumbers[this.step]
      if (activeStep > 3) activeStep--
      return activeStep
    },
    totalSteps() {
      let actualSteps = this.is_guest ? 8 : 4
      return actualSteps
    }
  },
};
</script>

<template>
  <div class="new-booking">
    <booking-progress :step="step" v-if="bookingID">

      <select-date
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :startTime="start_time"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_date'"
      />

      <select-location
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_location'"
      />

      <fill-description
          :bookingID="bookingID"
          :price="price"
          :artistSlug="artistSlug"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_description'"
      />

      <fill-name
          :activeStep="activeStepNum"
          :isClassBooking="true"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_name'"
      />

      <fill-email
          v-if="step === 'fill_email'"
          :isClassBooking="true"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
      />

      <fill-phone
          v-if="step === 'fill_phone'"
          :isClassBooking="true"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
      />

      <fill-password
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_password'"
      />
      <div v-if="step === 'booking_complete' || step === 'done'">
        <h4>
          Congratulations!
          <span>
            <font-awesome-icon :icon="['fas', 'circle-check']" style="color: #ff8141;" />
          </span>
        </h4>

        <p class="mt-4 mb-4">
          Your Class has been created
        </p>

        <p class="mb-5">
          you can proceed to payment after the discussion with artist
        </p>

        <h4 class="mb-4">Booking request summary</h4>

        <div class="new-booking__review-content">
          <h5 class="my-3">Description</h5>
          <div >
            <p class="booking-artist-detail__description text-break">{{ redactedBookingDescription(booking.class_description) }}</p>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-content-between">
              <div class="col-6 mx-0">
                <h5 class="mb-0">{{ booking.class_booking_dates.length > 1 ? 'Start date' : 'Event date' }}</h5>
                {{ moment(booking.class_booking_dates[0].date).format('DD MMM YYYY') }}
              </div>
              <div v-if="booking.class_booking_dates.length > 1" class="col-6 mx-0">
                <h5 class="mb-0">End date</h5>
                {{ moment(booking.class_booking_dates[booking.class_booking_dates.length - 1].date).format('DD MMM YYYY') }}
              </div>
            </div>

            <div class="d-flex mt-1">
              <div class="col-6 mx-0">
                <h5 class="mb-0">Start time</h5>
                {{ moment(booking.class_booking_dates[0].start_time).format('LT') }}
              </div>
              <div class="col-6 mx-0">
                <h5 class="mb-0">End time</h5>
                {{ moment(booking.class_booking_dates[0].end_time).format('LT') }}
              </div>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div class="col-6 mx-0">
              <h5 class="mb-0">Duration</h5>
              {{ booking.hours }}
            </div>
            <div class="col-6 mx-0">
              <h5 class="mb-0">Class reference</h5>
              {{ booking.id }}
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="col-12 mx-0">
              <h5 class="mb-0">Location</h5>
            </div>
            <div class="col-12 mx-0">
              {{ booking.address }}
            </div>
          </div>

          <div
              v-if="booking.latitude && booking.longitude"
              class="mt-2 mb-4 google_map"
              :data-latitude="`${parseFloat(booking.latitude)}`"
              :data-longitude="`${parseFloat(booking.longitude)}`"
              style="height: 165px;">
          </div>
        </div>

        <div class="mt-3">
          <v-app class="w-100 mb-4">
            <p class="mb-1">Step {{ activeStepNum }} of {{ totalSteps }}</p>
            <v-progress-linear
                rounded
                :value="activeStepNum * 100 / totalSteps"
                height="8">
            </v-progress-linear>
          </v-app>

          <div v-if="isMessageSelected" class="back-next">
            <button
                class="settings-forms__btn btn secondary fluid"
                @click="complete"
                :disabled="step === 'done'"
            >
              Review my event
            </button>
            <button
                class="settings-forms__btn btn primary fluid"
                @click="goToMessenger"
                :disabled="step === 'done'"
            >
              Go to Messenger
            </button>
          </div>

          <div v-else class="back-next">
            <button
                class="settings-forms__btn me-0 btn primary fluid"
                @click="complete"
                :disabled="step === 'done'">
              Review my event
            </button>
          </div>
        </div>
      </div>
    </booking-progress>
  </div>
</template>
<style lang="scss" scoped></style>
