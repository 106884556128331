<script>
  import RadialProgressBar from 'vue-radial-progress'
  import logoImg from '../../../images/logo.svg'

  export default {
    props: {
      step: String,
    },
    components: {
      RadialProgressBar
    },
    data(){
      return {
        logoImg: logoImg,
        isMessageSelected: JSON.parse(localStorage.getItem('isMessageSelected', 'false'))
      }
    },
    computed: {
      completedSteps(){
        if (['fill_name', 'fill_email', 'fill_phone', 'fill_password'].includes(this.step)){
          return 9
        }
        const steps = [
          'select_date', 'select_location',
          'select_time', 'fill_description', 'booking_complete'
        ]
        return steps.findIndex(_step => _step == this.step)
      },
      titleDisplay() {
        const step = this.step
        switch(step) {
          case "select_date":
            return "<span class='complete-signup-header__primary_title'>When</span> do you want to book your class?"
          case "select_location":
            return "<span class='complete-signup-header__primary_title'>What is your location</span> taking place?"
          case "fill_description":
            return ""
          case "booking_complete":
            return ""
          default:
            return ''
        }
      }
    }
  }
</script>

<template>
  <section>
    <slot name='header'>
      <div class="complete-signup-header mb-2" v-if="step != 'done' && step != 'message_info'">
<!--        <img :src="logoImg" class="all-signed-up-option-header__logo" v-if="step === 'booking_complete'" />-->
<!--        <radial-progress-bar-->
<!--          v-else-->
<!--          :diameter="84"-->
<!--          :strokeWidth="7"-->
<!--          :innerStrokeWidth="7"-->
<!--          innerStrokeColor="#FFDCCB"-->
<!--          startColor="#FF8141"-->
<!--          stopColor="#FF8141"-->
<!--          :animateSpeed="500"-->
<!--          :completed-steps="completedSteps"-->
<!--          :total-steps="11" class="complete-signup-header__logo">-->
<!--          <p>{{ parseInt(completedSteps * 100 / 11) }}%</p>-->
<!--        </radial-progress-bar>-->
        <div class="complete-signup-header__title" v-html="titleDisplay"></div>
      </div>
    </slot>

    <slot></slot>
  </section>
</template>
